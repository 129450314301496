/*
|--------------------------------------------------------------------------
| 404 Page.
|--------------------------------------------------------------------------
|
| The view that gets rendered when a user tries to visit a route that has
| no matching file in your /pages directory.
| If the user is authenticated, a link to the user home route (defined in your
| .env.local) will be displayed. Otherwise, a link to the homepage will be
| displayed.
|
*/
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import dynamic from "next/dynamic";
import Link from "next/link";
import Head from "next/head";
import { useEffect } from "react";
import { postBaseRequest, postCustomRequest } from "../js/config/axiosClient";

const BoxContainer = dynamic(() => import("../js/Components/Reusables/BoxContainer"));

function FourOFour(props) {
    /**
     * Determine the link location that the link
     * on the page will lead to. If the user is
     * authenticated, it will be the user home route.
     * Otherwise it will be the homepage.
     */
    // const linkLocation = props.isAuthenticated
    //     ? process.env.NEXT_PUBLIC_USER_HOME_ROUTE
    //     : "/";

    
    useEffect(() => {
        const data = {
            url: window.location.href,
            path: window.location.pathname.substring(1)
        }

        /**
         * =================== TODO ====================================
         * TODO: handle this problem later,needs research, 
         * possibly check/write up on stack overflow why the problem does not work
         * =============================================================
         */
        postBaseRequest('handle-404', data)
    }, [])
    
    const linkLocation = "/";
    return (
        <>
            <Head>
                <title>{`Page Not Found - ${process.env.brand.brand_name}`}</title>
            </Head>
            <BoxContainer className="mt-2 text-center">
                <Typography color="primary" className="mb-1 fw-bold" variant="h1" component="h1"> 404</Typography>
                <Typography className="mb-3 f-size_4" component="h1">Page not found</Typography>

                <Typography className="f-size_3 mb-4">The page you requested does not exist. Use the top menu to continue browsing, or use the search above.</Typography>

                <Divider className="mb-3"/>

                <Typography className="f-size_4">
                    <Link href={linkLocation}>
                        Go Home
                    </Link>
                </Typography>
            </BoxContainer>
        </>
    );
}

// // Map redux states to local component props.
// const mapStateToProps = (state) => ({
//     isAuthenticated: state.authReducer.isAuthenticated,
// });

// export default connect(mapStateToProps)(FourOFour);

export default FourOFour;
